import React from 'react';
import { AppBar, Toolbar, IconButton, Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { signOut } from "firebase/auth";
import { auth } from "../../shared/firebase";
import { colors } from "../lib/theme";
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HouseIcon from '@mui/icons-material/House';

const Header = ({ user, userData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.href = "/";
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: colors.darkNavy, mb: 6 }}>
      <Toolbar sx={{ minHeight: '48px', p: 1 }}>
        {isMobile ? (
          <IconButton
            color="inherit"
            onClick={() => window.location.href = "/dashboard"}
            sx={{ mr: 1, p: '4px' }}
          >
            <HouseIcon sx={{ fontSize: 20 }} />
          </IconButton>
        ) : (
          <Button
            variant="secondary"
            onClick={() => window.location.href = "/dashboard"}
            startIcon={<HouseIcon sx={{ fontSize: 16 }} />}
            sx={{ mr: 1, fontSize: '0.75rem', py: 0.5, px: 1 }}
          >
            Dashboard
          </Button>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Typography
          variant="subtitle1"
          sx={{
            mr: 1,
            fontSize: '1rem',
            fontWeight: 'medium'
          }}
        >
          {user ? `Welcome, ${userData?.firstName || user.email}` : 'Welcome'}
        </Typography>
        <IconButton
          color="inherit"
          onClick={() => window.location.href = "/profile"}
          sx={{ mr: 0.5, p: '4px' }}
        >
          <AccountCircleIcon sx={{ fontSize: 20 }} />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={handleLogout}
          sx={{ p: '4px' }}
        >
          <LogoutIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
